import { useNotificationContext } from "./useNotificationContext";

export const useShowNotification = () => {
  const { dispatch } = useNotificationContext();

  const showNotification = (message) => {
    dispatch({ type: message.type, payload: message.text });
  };

  return { showNotification };
};
