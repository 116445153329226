import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CompaniesList from "../../shared/CompaniesList";
import { api } from "../../utils/apiUtils";
import LoadingSpinner from "../../shared/LoadingSpinner";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import DipFinderChart from "./DipFinderChart";
import { useAuthContext } from "../../hooks/useAuthContext";

const DipFinder = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userTrackedCompanies, setUserTrackedCompanies] = useState([]);
  const inputRef = useRef(null);
  const { user } = useAuthContext();

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let companyData = [];

      try {
        const userCompanies = await api.post("dipfinder", {
          user: user?.email,
        });
        if (
          userCompanies &&
          userCompanies.data &&
          userCompanies.data[0] &&
          userCompanies.data[0].companies
        ) {
          setUserTrackedCompanies(userCompanies.data[0].companies);
          let i = 0;
          for (i; i < userCompanies.data[0].companies.length; i++) {
            const symbol = userCompanies.data[0].companies[i];
            try {
              const quote = await api.get(`quote?symbol=${symbol}`);
              const quoteData = quote ? quote.data : {};
              companyData.push(quoteData);
            } catch (error) {
              companyData.push({
                symbol: symbol,
                currency: "USD",
                change: 0,
                companyName: symbol,
                latestPrice: 0.0,
              });
            }
          }
          setData(companyData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      } catch {
        setData([]);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const addTicker = () => {
    if (inputRef.current) {
      if (
        typeof inputRef.current.value === "string" &&
        Array.isArray(userTrackedCompanies)
      ) {
        // userTrackedCompanies.push(inputRef.current.value);
        const updatedUserTrackedCompanies = userTrackedCompanies;
        updatedUserTrackedCompanies.push(inputRef.current.value);
        setUserTrackedCompanies(updatedUserTrackedCompanies);
        api
          .post(`addTrackerCompany`, {
            companies: updatedUserTrackedCompanies,
            user: user?.email,
          })
          .then(
            (response) => {
              api.get(`quote?symbol=${inputRef.current.value}`).then(
                (response) => {
                  if (Array.isArray(data) && response?.data) {
                    setData((data) => [...data, response.data]);
                  }
                  inputRef.current.value = "";
                },
                (error) => {
                  console.log("error");
                }
              );
            },
            (error) => {
              console.log("error");
            }
          );
      }
    }
  };

  const deleteTrackedCompany = (e, symbol) => {
    e.stopPropagation();
    const updatedUserTrackedCompanies = userTrackedCompanies;
    const index = updatedUserTrackedCompanies.findIndex(
      (company) => company.toLowerCase() === symbol.toLowerCase()
    );
    if (index > -1) {
      updatedUserTrackedCompanies.splice(index, 1);
      setUserTrackedCompanies(updatedUserTrackedCompanies);
    }

    api
      .post(`deleteTrackerCompany`, {
        companies: updatedUserTrackedCompanies,
        user: user?.email,
      })
      .then(
        (response) => {
          if (response && response.data && Array.isArray(data)) {
            const dataIndex = data.findIndex(
              (company) =>
                company?.symbol?.toLowerCase() === symbol?.toLowerCase()
            );

            if (dataIndex > -1) {
              setData(
                data.filter(
                  (i) => i.symbol.toLowerCase() !== symbol.toLowerCase()
                )
              );
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="dip-finder-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item sm={1} xs={12}></Grid>
          <Grid item sm={3} xs={12}>
            <div className="m50">
              <div
                className="center"
                style={{ border: "1px solid #d3d3d3", borderRadius: "10px" }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="ADD TICKER"
                  inputRef={inputRef}
                />
                <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={addTicker}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </IconButton>
              </div>
              <div className="center">
                <CompaniesList
                  data={data}
                  page="dipfinder"
                  deleteTrackedCompanyProp={deleteTrackedCompany}
                />
              </div>
            </div>
          </Grid>
          <Grid item sm={1} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <div className="m20">
              <DipFinderChart companies={userTrackedCompanies} />
            </div>
          </Grid>
          <Grid item sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DipFinder;
