import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { api } from "../../utils/apiUtils";
import { roundTwoDecimals } from "../../utils/numberUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Price vs 200d sma",
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function (value, index, array) {
        return roundTwoDecimals(value) + "%";
      },
      anchor: "end",
      offset: -20,
      align: "start",
    },
  },
  scales: {
    y: {
      ticks: {
        display: true,
        autoSkip: false,
        callback: function (value, index, array) {
          return value + "%";
        },
      },
    },
  },
};

const countSmaValue = (data) => {
  if (data.lastPrice && data.indicator) {
    const sma = (data.lastPrice / data.indicator - 1) * 100;
    return sma;
  }
  return 0;
};

const DipFinderChart = ({ companies }) => {
  const [sma, setSma] = useState([]);
  const [symbol, setSymbol] = useState([]);
  useEffect(() => {
    let smas = [];
    let symbols = [];
    if (companies && companies.length) {
      api
        .post("sma", {
          companies: companies,
        })
        .then((res) => {
          if (res && res.data) {
            console.log(res.data);
            res.data?.forEach((element) => {
              const smaValue = countSmaValue(element);
              smas.push(smaValue);
              symbols.push(element?.symbol);
            });

            setSma(smas);
            setSymbol(symbols);
          }
        });
    }
  }, [JSON.stringify(companies)]);

  const labels = symbol;

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: sma,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default DipFinderChart;
