import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { api } from "../../../utils/apiUtils";
import DipFinderChart from "../../dipFinder/DipFinderChart";
import EbitChart from "./EbitChart";
import EpsChart from "./EpsChart";
import NetIncomeChart from "./NetIncomeChart";
import PriceChart from "./PriceChart";
import RevenueChart from "./RevenueChart";
import SharesOutstanding from "./SharesOutstanding";

const InsightCharts = ({ data, chartData }) => {
  return (
    <Box sx={{ flexGrow: 1, textAlign: "center", margin: "3em" }}>
      <Grid container spacing={{ xs: 12, sm: 12, md: 5 }}>
        <Grid item sm={4} xs={12}>
          <PriceChart data={chartData} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <RevenueChart data={data} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EbitChart data={data} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <NetIncomeChart data={data} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <SharesOutstanding data={data} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <EpsChart data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsightCharts;
