import { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    console.log("useAuthContext must be used inside a provider");
  }

  return context;
};
