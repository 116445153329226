import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getReadableNumber } from "../../../utils/numberUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Price",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        display: true,
        autoSkip: false,
        callback: function (value, index, array) {
          return getReadableNumber(value);
        },
      },
    },
  },
};

const PriceChart = ({ data }) => {
  console.log(data);
  let priceData = [];
  let priceDate = [];
  data?.forEach((element) => {
    priceData.push(element?.close);
    priceDate.push(element?.date);
  });

  const labels = priceDate?.reverse();

  const chartData = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: priceData?.reverse(),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default PriceChart;
