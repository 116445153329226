import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../shared/LoadingSpinner";
import CompaniesList from "../../shared/CompaniesList";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import { BASE_URL } from "../../utils/apiUtils";

const InsightDefault = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();

  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });

  useEffect(() => {
    setLoading(true);
    if (!user) {
      window.location.reload();
    }

    api.get("insight").then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          setData(res.data);
          setLoading(false);
        }
      },
      (error) => {
        console.log("ERROR");
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  } else if (data) {
    return (
      <div className="insight-default center">
        <CompaniesList data={data} />
      </div>
    );
  } else {
    return null;
  }
};

export default InsightDefault;
