import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { auth } from "../utils/apiUtils";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (credentials) => {
    setLoading(true);
    setError(null);
    const response = await auth.post("login", credentials);

    if (response?.data) {
      window.localStorage.setItem("user", JSON.stringify(response?.data));

      dispatch({ type: "LOGIN", payload: response.data });
      setLoading(false);
    }
  };

  return { login, loading, error };
};
