import { useState, forwardRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import { useNotificationContext } from "../hooks/useNotificationContext";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notifications = () => {
  const [open, setOpen] = useState(null);
  const { notification } = useNotificationContext();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  if (notification) {
    return (
      <Snackbar open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={notification.type}
          sx={{ width: "100%" }}
        >
          {notification.text}
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

export default Notifications;
