import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSignup } from "../hooks/useSignup";
import { useShowNotification } from "../hooks/useShowNotification";

const SignUp = () => {
  const { signup, loading, error } = useSignup();
  const { showNotification } = useShowNotification();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // e.preventDefault();
    registerUser(data);
  };

  const registerUser = async (credentials) => {
    signup(credentials).then(
      (response) => {
        navigate("/");
      },
      (error) => {
        showNotification({ type: "ERROR", text: error.response.data });
      }
    );
  };

  return (
    <div className="login-wrapper">
      <h1>Please Sign Up</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            {...register("email", { required: "Requierd" })}
            error={!!errors.email}
            helperText={errors?.email ? errors.email.message : null}
          />
        </div>
        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            {...register("name", { required: "Requierd" })}
            error={!!errors.name}
            helperText={errors?.name ? errors.name.message : null}
          />
        </div>
        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            {...register("password", { required: "Requierd" })}
            error={!!errors.password}
            helperText={errors?.password ? errors.password.message : null}
          />
        </div>
        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Repeat Password"
            type="password"
            variant="outlined"
            {...register("repassword", { required: "Requierd" })}
            error={!!errors.repassword}
            helperText={errors?.repassword ? errors.repassword.message : null}
          />
        </div>
        <div>
          <Button type="submit" variant="contained">
            Sign Up
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
