export const roundTwoDecimals = (number) => {
  if (number) {
    return number.toFixed(2);
  }
  return "-";
};

export const getPercent = (number) => {
  if (number) {
    const percentNumber = number * 100;
    const percentage = `${roundTwoDecimals(percentNumber)} %`;
    return percentage;
  }
  return "-";
};

export const getReadableNumber = (number) => {
  const value = roundTwoDecimals(number);
  if (value !== null && value !== undefined) {
    if (value > 0 || value === 0) {
      return value < 1000
        ? `$${value}`
        : value < 1000000
        ? `$${value / 1000}K`
        : `$${value / 1000000}M`;
    }
    if (value < 0) {
      return value > -1000
        ? `$${value}`
        : value > -1000000
        ? `$${value / 1000}K`
        : `$${value / 1000000}M`;
    }
  }
  return "-";
};
