import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InsightResults from "./InsightResults";
import { api } from "../../utils/apiUtils";
import InsightDefault from "./InsightDefault";

const Insights = () => {
  const [term, setTerm] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      term &&
        api.get(`/search?term=${term}`).then(
          (res) => {
            if (res && res.status === 200 && res.data) {
              setResults(res.data);
            }
          },
          (error) => {
            console.log("ERROR");
          }
        );
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [term]);

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const search = () => {
    // getQuote();
  };

  return (
    <div className="insight">
      <div style={{ marginTop: "50px" }}></div>
      <FormControl sx={{ m: 1, width: "75ch" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment">Search</InputLabel>
        <OutlinedInput
          fullWidth
          id="outlined-adornment"
          type="text"
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle" onClick={search} edge="end">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </IconButton>
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl>
      {(!term || results === null) && window.location.pathname === "/" ? (
        <InsightDefault />
      ) : (
        <InsightResults data={results} term={term} />
      )}
    </div>
  );
};

export default Insights;
