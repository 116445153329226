import axios from "axios";
const user = JSON.parse(localStorage.getItem("user"));

// const BASE_URL = "https://api-kitinversor.herokuapp.com/";
// const BASE_URL = "";
export const AUTH_URL = "https://cool-ascent-371115.ew.r.appspot.com/";
export const BASE_URL = "https://cool-ascent-371115.ew.r.appspot.com/api";
// export const AUTH_URL = "http://localhost:8080";
// export const BASE_URL = "http://localhost:8080/api";

export const auth = axios.create({
  baseURL: AUTH_URL,
});

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${user?.token}`,
  },
});
