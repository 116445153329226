import React from "react";

const QuoteValues = ({ title, value }) => {
  return (
    <div className="quote-values-container">
      <div className="quote-values">
        <div className="quote-values-title">
          <b>{title}:</b>
        </div>
        <div className="quote-values-value">{value}</div>
      </div>
    </div>
  );
};

export default QuoteValues;
