import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLogin } from "../hooks/useLogin";
import { useShowNotification } from "../hooks/useShowNotification";

const Login = ({ setUser }) => {
  // const [loading, setLoading] = useState(false);
  const { login, error, loading } = useLogin();
  const { showNotification } = useShowNotification();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    loginUser(data);
  };

  const loginUser = (credentials) => {
    login(credentials).then(
      (response) => {
        navigate("/");
      },
      (error) => {
        showNotification({ type: "ERROR", text: error.response.data });
      }
    );
  };

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            {...register("email", { required: "Requierd" })}
            error={!!errors.email}
            helperText={errors?.email ? errors.email.message : null}
          />
        </div>

        <div className="m20">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            {...register("password", { required: "Requierd" })}
            error={!!errors.password}
            helperText={errors?.password ? errors.password.message : null}
          />
        </div>
        <div>
          <Button type="submit" variant="contained">
            Log In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
