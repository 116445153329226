import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Button, ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol } from "../utils/currencyUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { getThemeProps } from "@mui/system";

const CompaniesList = ({ data, page, deleteTrackedCompanyProp }) => {
  const navigate = useNavigate();
  const getQuote = (symbol) => {
    const path = `quote/${symbol}`;
    navigate(path);
  };

  const renderChangeButton = (change) => {
    const color = change >= 0 ? "success" : "error";
    return (
      <Box edge="end">
        <Button size="small" variant="contained" color={color}>
          {change}
        </Button>
      </Box>
    );
  };

  const deleteTrackedCompany = (e, symbol) => {
    e.stopPropagation();
    if (
      deleteTrackedCompanyProp &&
      typeof deleteTrackedCompanyProp === "function"
    ) {
      deleteTrackedCompanyProp(e, symbol);
    }
  };

  return (
    <Box sx={{ m: 1, width: "75ch" }}>
      {data?.map((quote, index) => {
        return (
          <div key={index}>
            <nav aria-label="main mailbox folders">
              <List onClick={() => getQuote(quote.symbol)}>
                <ListItem
                  disablePadding
                  secondaryAction={
                    <>
                      <Box edge="end">
                        <span>
                          {getCurrencySymbol(quote.currency)}
                          {quote.latestPrice}
                        </span>
                      </Box>
                      {renderChangeButton(quote.change)}
                    </>
                  }
                >
                  <ListItemButton>
                    {page === "dipfinder" && (
                      <div className="hoverBox">
                        <ListItemIcon
                          onClick={(e) => deleteTrackedCompany(e, quote.symbol)}
                        >
                          <FontAwesomeIcon icon={faX} />
                        </ListItemIcon>
                      </div>
                    )}
                    <ListItemText
                      primary={quote.companyName}
                      secondary={quote.symbol}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
            <Divider />
          </div>
        );
      })}
    </Box>
  );
};

export default CompaniesList;
