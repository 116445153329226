import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const InsightResults = (props) => {
  const [showResults, setShowResutls] = useState(false);
  const navigate = useNavigate();
  const getQuote = (symbol) => {
    setShowResutls(false);
    const path = `/quote/${symbol}`;
    navigate(path);
  };

  useEffect(() => {
    if (props.term) {
      setShowResutls(true);
    }
  }, [props.term]);

  if (props?.data?.length > 0 && showResults) {
    return (
      <div className="insight-results">
        <Box
          sx={{
            width: "100%",
            maxWidth: "75ch",
            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            position: "absolute",
          }}
        >
          {props.data.map((quote, index) => {
            return (
              <div key={index}>
                <nav aria-label="main">
                  <List onClick={() => getQuote(quote.symbol)}>
                    <ListItem
                      disablePadding
                      secondaryAction={
                        <>
                          <Box edge="end">
                            <span>{quote.name}</span>
                          </Box>
                        </>
                      }
                    >
                      <ListItemButton>
                        <ListItemText primary={quote.symbol} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
                <Divider />
              </div>
            );
          })}
        </Box>
      </div>
    );
  }

  return null;
};

export default InsightResults;
