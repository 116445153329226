import { createContext, useEffect, useReducer } from "react";

export const NotificationContext = createContext();

export const notificationReducer = (state, action) => {
  switch (action.type) {
    case "ERROR":
      return { notification: { type: "error", text: action.payload } };
    case "SUCCESS":
      return { notification: { type: "success", text: action.payload } };
    default:
      return state;
  }
};

export const NotificationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, {
    notification: null,
  });

  // console.log("NotificationContext state: ", state);

  return (
    <NotificationContext.Provider value={{ ...state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};
