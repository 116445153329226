import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Quote from "./pages/quote/Quote";
import Navbar from "./shared/Navbar";
import DipFinder from "./pages/dipFinder/DipFinder";
import Insights from "./pages/insights/Insights";
import Login from "./login/Login";
import SignUp from "./login/Signup";
import Notifications from "./shared/Notifications";
import { useNotificationContext } from "./hooks/useNotificationContext";
import { useAuthContext } from "./hooks/useAuthContext";

function App() {
  const { notification } = useNotificationContext();
  const { user } = useAuthContext();

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="signup" element={<SignUp />} />
        <Route exact path="login" element={<Login />} />
        <Route
          exact
          path="/"
          element={user?.token ? <Insights /> : <Login />}
        />
        <Route
          path="quote/:symbol"
          element={user?.token ? <Quote /> : <Login />}
        />
        <Route
          path="dip-finder"
          element={user?.token ? <DipFinder /> : <Login />}
        />
      </Routes>

      {notification && <Notifications />}
    </div>
  );
}

export default App;
