import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getReadableNumber } from "../../../utils/numberUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "EPS",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        display: true,
        autoSkip: false,
        callback: function (value, index, array) {
          return getReadableNumber(value);
        },
      },
    },
  },
};

const EpsChart = ({ data }) => {
  let epsData = [];
  let epsDate = [];
  data?.forEach((element) => {
    epsData.push(element?.incomeNetPerWabsoSplitAdjusted);
    epsDate.push(element?.periodEndDate);
  });

  const labels = epsDate?.reverse();

  const chartData = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: epsData?.reverse(),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default EpsChart;
