import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import QuoteValues from "./QuoteValues";
import {
  getPercent,
  getReadableNumber,
  roundTwoDecimals,
} from "../../utils/numberUtils";
import { getCurrencySymbol } from "../../utils/currencyUtils";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../shared/LoadingSpinner";
import Insights from "../insights/Insights";
import { api } from "../../utils/apiUtils";
import InsightCharts from "../insights/insightCharts/InsightCharts";
import DipFinderChart from "../dipFinder/DipFinderChart";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgb(24, 119, 242, 0.5)",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Quote = () => {
  const [data, setData] = useState([]);
  const [fundamentalData, setFundamentalData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fundamentalLoading, setFundamentalLoading] = useState(false);
  const { symbol } = useParams();

  useEffect(() => {
    setLoading(true);
    api.get(`/company?symbol=${symbol}`).then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          setData(res.data);
          setLoading(false);
        }
      },
      (error) => {
        console.log("ERROR");
        setLoading(false);
      }
    );
    api.get(`/fundamentals?symbol=${symbol}`).then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          console.log(res.data);
          setFundamentalData(res.data);
          setFundamentalLoading(false);
        }
      },
      (error) => {
        console.log("ERROR");
        setFundamentalLoading(false);
      }
    );
    api.get(`/charts?symbol=${symbol}`).then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          console.log(res.data);
          setChartData(res.data);
          setFundamentalLoading(false);
        }
      },
      (error) => {
        console.log("ERROR");
        setFundamentalLoading(false);
      }
    );
  }, [symbol]);

  const fundamentalValuationsData = data?.fundamentalValuations?.[0];
  const balanceSheetData = data?.balanceSheet?.balancesheet?.[0];
  const advancedStatsData = data?.advancedStats;
  const reportedFinancialsData = data?.reportedFinancials;
  const upcomingEventsData = data?.upcomingEvents;

  const calculateShareDilution = () => {
    let shareDilution = 0;
    const lastQuarter =
      reportedFinancialsData?.[3]
        ?.WeightedAverageNumberOfSharesOutstandingBasic;
    const currentQuarter =
      reportedFinancialsData?.[0]
        ?.WeightedAverageNumberOfSharesOutstandingBasic;
    if (lastQuarter && currentQuarter) {
      shareDilution = (currentQuarter / lastQuarter - 1) * 100;
    }

    return shareDilution;
  };

  const shareDilution = calculateShareDilution();

  const cash = balanceSheetData?.currentCash;
  const debt =
    balanceSheetData?.currentLongTermDebt &&
    balanceSheetData?.longTermDebt &&
    balanceSheetData?.currentLongTermDebt + balanceSheetData?.longTermDebt;
  const net = debt && cash && debt - cash;

  if (loading) {
    return (
      <>
        <Insights />
        <LoadingSpinner />
      </>
    );
  }
  return (
    <div className="quote-container">
      <Insights />
      <h2 className="company-title">{advancedStatsData?.companyName}</h2>
      <div className="quote center">
        <Box sx={{ flexGrow: 1, textAlign: "center", margin: "5em" }}>
          <Grid container spacing={{ xs: 12, sm: 12, md: 1 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Item>
                <h3>Value</h3>
                <QuoteValues
                  title="Market Cap"
                  value={getReadableNumber(advancedStatsData?.marketcap)}
                />
                <QuoteValues
                  title="PE (TTM|FWD)"
                  value={
                    roundTwoDecimals(fundamentalValuationsData?.pToE) |
                    roundTwoDecimals(advancedStatsData?.forwardPERatio)
                  }
                />
                <QuoteValues
                  title="Price To Sales (TTM)"
                  value={roundTwoDecimals(
                    fundamentalValuationsData?.priceToRevenue
                  )}
                />
                <QuoteValues
                  title="EV To EBITDA (TTM)"
                  value={roundTwoDecimals(
                    fundamentalValuationsData?.evToEbit * 100
                  )}
                />
                <QuoteValues
                  title="Price to Book (TTM)"
                  value={roundTwoDecimals(fundamentalValuationsData?.pToBv)}
                />
                <QuoteValues
                  title="Free Cash Flow Yield (TTM)"
                  value={getPercent(fundamentalValuationsData?.fcfYield)}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Item>
                <h3>Margins</h3>
                <QuoteValues
                  title="Margen Neto"
                  value={getPercent(advancedStatsData?.profitMargin)}
                />
                <QuoteValues
                  title="Operating Margin (TTM)"
                  value={getPercent(fundamentalValuationsData?.ebitToRevenue)}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Item>
                <h3>Crecimiento y Rentabilidad</h3>
                <QuoteValues
                  title="ROE"
                  value={getPercent(fundamentalValuationsData?.returnOnEquity)}
                />
                <QuoteValues
                  title="ROCE"
                  value={getPercent(fundamentalValuationsData?.roce)}
                />
                <QuoteValues
                  title="Quarterly Earnings (YoY)"
                  value={getReadableNumber(
                    reportedFinancialsData?.NetIncomeLoss
                  )}
                />
                <QuoteValues
                  title="Quarterly Revenue (YoY)"
                  value={getReadableNumber(
                    reportedFinancialsData?.RevenueFromContractWithCustomerExcludingAssessedTax
                  )}
                />
                <QuoteValues
                  title="Dilución de Acciones"
                  value={getPercent(shareDilution)}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Item>
                <h3>Balance</h3>
                <QuoteValues
                  title="Cash"
                  value={`${getCurrencySymbol(
                    balanceSheetData?.currency
                  )}${getReadableNumber(cash)}`}
                />
                <QuoteValues
                  title="Debt"
                  value={`${getCurrencySymbol(
                    balanceSheetData?.currency
                  )}${getReadableNumber(debt)}`}
                />
                <QuoteValues
                  title="Net"
                  value={`${getCurrencySymbol(
                    balanceSheetData?.currency
                  )}${getReadableNumber(net)}`}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Item>
                <h3>Dividend</h3>
                <QuoteValues
                  title="Dividend Yield"
                  value={getPercent(fundamentalValuationsData?.dividendYield)}
                />
                <QuoteValues
                  title="Payout Ratio"
                  value={getPercent(
                    fundamentalValuationsData?.dividendYield /
                      fundamentalValuationsData?.earningsYield
                  )}
                />
                <QuoteValues
                  title="Ex-Div Date"
                  value={advancedStatsData?.nextDividendDate}
                />
                <QuoteValues
                  title="Payout Date"
                  value={advancedStatsData?.nextDividendDate}
                />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <InsightCharts data={fundamentalData} chartData={chartData} />
      </div>
    </div>
  );
};

export default Quote;
